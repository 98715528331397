.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background: #fafbfb;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: rgb(53, 53, 53);
}

.title {
  font-size: 2em;
  text-align: center;
  font-family: "Bebas Neue", sans-serif;
}

h3 {
  padding-top: 0.25em;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 2em 2em 2em !important;
}

.date {
  color: rgb(28, 29, 29);
}

#description {
  margin: 1.5em 0 2em 0;
}

.button {
  text-decoration: none;
  padding: 0.5em;
  border-radius: 5px;
  color: white;
}

.workButton {
  background-color: #80f;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.workButton:hover {
  background-color: #f08;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.schoolButton {
  background-color: #f9c74f;
}

.schoolButton:hover {
  background-color: #f3bc3c;
}

@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
    margin-top: 1.5em;
  }
}

.accordion {
  --bs-accordion-active-bg: #f08;
  --bs-accordion-active-color: white;
}

.accordion-header {
  background-color: #f08;
}

.accordion-body {
  background-color: rgb(245, 244, 244);
}

ul.square {
  list-style-type: square;
}

.social-media-icon a {
  font-size: 2rem;
}

.btn-primary {
  background-color: #80f;
  border: #80f;
  font-weight: normal;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f08;
  --bs-btn-hover-border-color: #f08;
}
.btn-primary:hover {
  background-color: #f08;
  color: white;
}
